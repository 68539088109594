import axios from "axios";
import { getCredentials } from "./auth";
import config from "../config";

export async function makeSubscription(paymentMethodID, email, planNum) {
  try {
    const { token, csrf } = await getCredentials();
    const options = {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        csrf,
      },
      url: `${config.BackendURL}sub`,
      data: { paymentMethod: paymentMethodID, email: email, planNum: planNum },
    };
    const res = await axios(options);
    const { clientSecret, status } = res.data;
    if (clientSecret && status) {
      return { clientSecret, status };
    }
    throw new Error("Inavlid client secret or status on the payment details");
  } catch (e) {
    return null;
  }
}

export async function getSubscriptionFromDB(email) {
  try {
    const { token, csrf } = await getCredentials();
    const options = {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
        csrf,
        // "Access-Control-Allow-Origin": config.BackendURL,
      },
      url: `${config.BackendURL}getsub`,
      data: { email: email },
    };
    const res = await axios(options);
    const lastSub = res.data.message;
    if (!lastSub) throw new Error("No subscription for this user!");
    return lastSub;
  } catch (e) {
    return null;
  }
}

// export async function getApiCallsLeft(email) {
//   try {
//     const { token, csrf } = await getCredentials();
//     const options = {
//       method: "POST",
//       headers: {
//         Authorization: `Token ${token}`,
//         csrf,
//         "Access-Control-Allow-Origin": config.BackendURL,
//       },
//       url: `${config.BackendURL}getsub`,
//       data: { email: email },
//     };
//     const res = await axios(options);
//     const lastSub = res.data.message;
//     if (!lastSub) throw new Error("No subscription for this user!");
//     return lastSub;
//   } catch (e) {
//     return null;
//   }
// }


export async function createCheckoutSession(planType) {
  const { token, csrf } = await getCredentials();
  const options = {
    method: "GET",
    headers: {
      Authorization: `Token ${token}`,
      csrf,
    },
    url: `${config.BackendURL}stripe/create-stripe-checkout-session`,
    params: { planType }
  };
  const res = await axios(options);
  if (!res.data.url) throw new Error("No session url found");
  return res.data.url;
}

export async function getCheckoutSession(sessionId) {
  const { token, csrf } = await getCredentials();
  const options = {
    method: "POST",
    headers: {
      Authorization: `Token ${token}`,
      csrf,
    },
    url: `${config.BackendURL}stripe/get-stripe-checkout-session`,
    data: { sessionId }
  };
  const res = await axios(options);
  if (!res.data) throw new Error("No returned data found");
  return res.data;
}