let configVars = {
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLICKEY,
  BackendURL: process.env.REACT_APP_BACKEND_URL,
  SERP_API_URL: process.env.REACT_APP_SERP_API_URL,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  DOCS_URL: process.env.REACT_APP_DOCS_URL,
  plan1: {
    name: "Personal",
    id: "",
    price: "$19",
  },
  plan2: {
    name: "Developer",
    id: "",
    price: "$49",
  },
  plan3: {
    name: "Business",
    id: "",
    price: "$199",
  },
  planIdMap: {
    price_1INq1tByqRsrOEgK6RytK8ZO: "Personal",
    price_1HrQNnByqRsrOEgK1EPsqEEj: "Developer",
    price_1MxtpmByqRsrOEgKiPHbjnNg: "Business",
  },
};

configVars.planIdMap[process.env.REACT_APP_PRICE_ID_PERSONAL] = "Personal";
configVars.planIdMap[process.env.REACT_APP_PRICE_ID_DEVELOPER] = "Developer";
configVars.planIdMap[process.env.REACT_APP_PRICE_ID_BUSINESS] = "Business";

export default configVars;
