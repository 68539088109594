import React, { lazy, useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import authContext from "./context/authContext";
import userContext from "./context/userContext";
import subscriptionContext from "./context/subscriptionContext";
import newPaymentContext from "./context/newPayment";
import { isAuthenticated } from "./service/auth";
import { getSubscriptionFromDB } from "./service/payments";
import PrivateRoute from "./routes/private";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import config from "./config";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Landing = lazy(() => import("./pages/LandingPage"));
const Verify = lazy(() => import("./pages/verify"));
const Activate = lazy(() => import("./pages/Activate"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const test = lazy(() => import("./pages/ResetSucess"));
const Docs = lazy(() => import("./pages/Docs"));
const NoMatch = lazy(() => import("./pages/404"));
const privacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const termsOfService = lazy(() => import("./pages/TermsOfService"));
const stripePromise = loadStripe(config.stripePublicKey);

function App() {
  const [authentication, setAuthentication] = useState();
  const [user, setUser] = useState("");
  const [subscription, setSubscription] = useState(null);
  const [newPayment, setNewPayment] = useState(null);
  const history = useHistory("/app");

  const getAuth = async () => {
    const auth = await isAuthenticated();
    if (auth !== undefined) {
      setAuthentication(true);
      setUser(auth);
    }
  };

  const getSubscription = async () => {
    if (authentication) {
      const sub = await getSubscriptionFromDB(user.email);
      setSubscription(sub);
    }
    return;
  };

  useEffect(() => {
    getAuth();
    getSubscription();
    if (authentication) {
      console.log(history);
    }
    // eslint-disable-next-line
  }, [user.email]);
  return (
    <>
      <authContext.Provider value={{ authentication, setAuthentication }}>
        <userContext.Provider value={{ user, setUser }}>
          <subscriptionContext.Provider
            value={{ subscription, setSubscription }}
          >
            <newPaymentContext.Provider value={{ newPayment, setNewPayment }}>
              <Router>
                <AccessibleNavigationAnnouncer />
                <Switch>
                  <Route path="/" exact component={Landing} />
                  <Route path="/login" component={Login} />
                  <Route path="/signup" component={CreateAccount} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/email/verify" exact component={Verify} />
                  <Route path="/docs" exact component={Docs} />
                  <Route path="/privacy" exact component={privacyPolicy} />
                  <Route
                    path="/termsofservice"
                    exact
                    component={termsOfService}
                  />
                  <Route
                    path="/users/activate/:token"
                    exact
                    render={(props) => <Activate {...props} />}
                  />
                  <Route
                    path="/users/reset/:token/:identifier"
                    exact
                    render={(props) => <ResetPassword {...props} />}
                  />
                  <Route path="/test" exact component={test} />
                  <Elements stripe={stripePromise}>
                    <PrivateRoute path="/app" component={Layout} />
                  </Elements>
                  <Route path="*" component={NoMatch} />
                </Switch>
              </Router>
            </newPaymentContext.Provider>
          </subscriptionContext.Provider>
        </userContext.Provider>
      </authContext.Provider>
    </>
  );
}

export default App;
