import axios from "axios";
import Cookies from "js-cookie";
import config from "../config";

const BACKEND_API = config.BackendURL;

const api = axios.create({
  baseURL: BACKEND_API,
  // headers: { "Access-Control-Allow-Origin": "*" },
});

export function getCredentials() {
  try {
    const csrf = localStorage.getItem("csrf");
    const token = Cookies.get("auth-token");
    if (!token || !csrf) {
      return null;
    }
    return { token, csrf };
  } catch (e) {
    return null;
  }
}

export async function isAuthenticated() {
  try {
    const csrf = await localStorage.getItem("csrf");
    const token = await Cookies.get("auth-token");
    if (!token) return undefined;
    if (!csrf) return undefined;
    if (token == undefined || csrf == undefined) return undefined;
    const response = await api.get("user", {
      headers: {
        Authorization: `Token ${token}`,
        csrf,
      },
      // withCredentials: true,
    });
    if (response.status === 200 && response.data.success === true) {
      return response.data.message;
    }
  } catch (e) {
    clearCredentials();
    return undefined;
  }
}

export async function signup({ username, email, password }) {
  try {
    const response = await api.post("register", {
      username,
      email,
      password,
    });
    if (response.status === 201 && response.data.success === true) {
      return true;
    }
  } catch (e) {
    return e.response ? e.response.data.error : "Unable to sign up";
  }
}

export async function login({ email, password }) {
  try {
    const response = await api.post("login", {
      email,
      password,
    });
    if (response.status === 200 && response.data.success === true) {
      Cookies.set("auth-token", response.data.message.token, { expires: 7 });
      localStorage.setItem("csrf", response.data.message["csrf"]);
      return true;
    }
    return false;
  } catch (e) {
    if (e.response?.data?.error === "Activate your account") {
      return null;
    }
    return false;
  }
}

export async function activateAccount(activationToken) {
  try {
    const response = await api.post("activate", {
      activationToken,
    });
    if (response.status === 200 && response.data.success === true) {
      Cookies.set("auth-token", response.data.message.token, { expires: 7 });
      localStorage.setItem("csrf", response.data.message["csrf"]);
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

export async function verifyEmail(email) {
  try {
    const response = await api.post("verify", {
      email,
    });
    if (response.status === 200 && response.data.success === true) {
      return response.data;
    }
  } catch (e) {
    return e.response.data;
  }
}

export async function forgotPassword({ email }) {
  try {
    const response = await api.post("reset", {
      email,
    });
    if (response.status === 200 && response.data.success === true) {
      return true;
    }
  } catch (e) {
    return e.response?.data
      ? e.response.data.error
      : "Invalid Email, Check your email and try again";
  }
}

export async function resetPassword({ resetToken, password, id }) {
  try {
    const response = await api.put("resetpassword", {
      resetToken,
      password,
      id,
    });
    if (response.status === 200 && response.data.success === true) {
      return true;
    }
  } catch (e) {
    return e.response
      ? e.response.data.error
      : "Invalid or expired activation link";
  }
}

export async function googleOauth(tokenId) {
  try {
    const response = await api.post("googleoauth", {
      idToken: tokenId,
    });
    if (response.status === 200 && response.data.success === true) {
      Cookies.set("auth-token", response.data.message.token, { expires: 7 });
      localStorage.setItem("csrf", response.data.message["csrf"]);
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
}

export function clearCredentials() {
  try {
    Cookies.remove("auth", { path: "" });
    localStorage.removeItem("csrf");
  } catch (e) {}
}
